
@font-face {
    font-family: 'Menlo';
    src: url('menlo-regular-webfont.woff2') format('woff2'),
         url('menlo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Consolas';
    src: url('consola-webfont.woff2') format('woff2'),
         url('consola-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Consolas';
    src: url('consolab-webfont.woff2') format('woff2'),
         url('consolab-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Consolas';
    src: url('consolai-webfont.woff2') format('woff2'),
         url('consolai-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Consolas';
    src: url('consolas-webfont.woff2') format('woff2'),
         url('consolas-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Consolas';
    src: url('consolaz-webfont.woff2') format('woff2'),
         url('consolaz-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}