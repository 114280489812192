
@font-face {
    font-family: 'Courier New';
    src: url('cour-webfont.woff2') format('woff2'),
         url('cour-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Courier New';
    src: url('fontsfree-net-cour-webfont.woff2') format('woff2'),
         url('fontsfree-net-cour-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf Mono';
    src: url('fontsfree-net-sfmono-regular-webfont.woff2') format('woff2'),
         url('fontsfree-net-sfmono-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Good Times';
    src: url('good_times-webfont.woff2') format('woff2'),
         url('good_times-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Icomoon';
    src: url('icomoon-webfont.woff2') format('woff2'),
         url('icomoon-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nimbus Sans';
    src: url('nimbus-sans-webfont.woff2') format('woff2'),
         url('nimbus-sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('oswald-variablefont_wght-webfont.woff2') format('woff2'),
         url('oswald-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-webfont.woff2') format('woff2'),
         url('sailec-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-bold-webfont.woff2') format('woff2'),
         url('sailec-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-medium-webfont.woff2') format('woff2'),
         url('sailec-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}