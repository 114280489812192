
@font-face {
    font-family: 'Sailec';
    src: url('sailec_bold-webfont.woff2') format('woff2'),
         url('sailec_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec_medium-webfont.woff2') format('woff2'),
         url('sailec_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec_light-webfont.woff2') format('woff2'),
         url('sailec_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec_thin-webfont.woff2') format('woff2'),
         url('sailec_thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec_regular_italic-webfont.woff2') format('woff2'),
    url('sailec_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('helvetica_neue_regular-webfont.woff2') format('woff2'),
    url('helvetica_neue_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('helvetica_neue_97_black_condensed_oblique-webfont.woff2') format('woff2'),
         url('helvetica_neue_97_black_condensed_oblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('helvetica_neue_67_medium_condensed-webfont.woff2') format('woff2'),
         url('helvetica_neue_67_medium_condensed-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('helvetica_neue_97_black_condensed-webfont.woff2') format('woff2'),
         url('helvetica_neue_97_black_condensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('helvetica_neue_67_medium_condensed_oblique-webfont.woff2') format('woff2'),
    url('helvetica_neue_67_medium_condensed_oblique-webfont.woff') format('woff');
    font-weight: 500;
    font-style: oblique;
}