
@font-face {
    font-family: 'Courier New';
    src: url('fontsfree-net-cour-webfont.woff2') format('woff2'),
         url('fontsfree-net-cour-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Courier New';
    src: url('cour-webfont.woff2') format('woff2'),
         url('cour-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf Mono';
    src: url('fontsfree-net-sfmono-regular_1-webfont.woff2') format('woff2'),
         url('fontsfree-net-sfmono-regular_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf Mono';
    src: url('fontsfree-net-sfmono-regular-webfont.woff2') format('woff2'),
         url('fontsfree-net-sfmono-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Icomoon';
    src: url('icomoon-webfont.woff2') format('woff2'),
         url('icomoon-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-bold-webfont.woff2') format('woff2'),
         url('sailec-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-medium-webfont.woff2') format('woff2'),
         url('sailec-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sailec';
    src: url('sailec-webfont.woff2') format('woff2'),
         url('sailec-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}