

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Slab", serif;
}

a {
  text-decoration: none;
}

#inlogContainer::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text {
  position:relative;
  line-height:2em;
  overflow:hidden;
}
.fadingEffect {
  position:absolute;
  top:0; bottom:0; right:0;
  width:100%;
  background:linear-gradient(0deg, rgba(243, 243, 247, 1) 0%, rgba(243, 243, 247, 0) 100%);
  -moz-animation: showHide 0s ease-in forwards 1; /* Firefox */
  -webkit-animation: showHide 0s ease-in forwards 1; /* Safari and Chrome */
  -ms-animation: showHide 0s ease-in forwards 1; /* IE10 */
  -o-animation: showHide 0s ease-in forwards 1; /* Opera */
  animation: showHide 0s ease-in forwards 1;
}
@-webkit-keyframes showHide { /* Chrome, Safari */
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:0%;}
}
@-moz-keyframes showHide { /* FF */
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:0%;}
}
@-ms-keyframes showHide { /* IE10 */
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:0%;}
}
@-o-keyframes showHide { /* Opera */
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:0%;}
}
@keyframes showHide {
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:0%;}
}

@font-face {
  font-family: 'Goodtimes';
  src: local('Goodtimes'), url(./assets/fonts/Good_Times.otf) format('opentype');
}
@font-face {
  font-family: 'Nimbus';
  src: local('Nimbus'), url(./assets/fonts/nimbus-sans.otf) format('opentype');
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #282c34; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.slider2 {
  -webkit-appearance: none;
 /* width: 100%;*/
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider2::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

/* Mouse-over effects */
.slider2:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider2::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider2::-moz-range-thumb {
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}